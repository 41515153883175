// Customers logos

import bankOfAmericaLogo from '@/ui/assets/customers/svg/bankOfAmerica.svg';
import barclaysLogo from '@/ui/assets/customers/svg/barclays.svg';
import blackRockLogo from '@/ui/assets/customers/svg/blackRock.svg';
import blackstoneLogo from '@/ui/assets/customers/svg/blackstone.svg';
import carlyleLogo from '@/ui/assets/customers/svg/carlyle.svg';
import CIBCLogo from '@/ui/assets/customers/svg/CIBC.svg';
import citigroupLogo from '@/ui/assets/customers/svg/citigroup.svg';
import dellLogo from '@/ui/assets/customers/svg/dell.svg';
import fordLogo from '@/ui/assets/customers/svg/ford.svg';
import goldmanSachsLogo from '@/ui/assets/customers/svg/goldmanSachs.svg';
import guggenheimLogo from '@/ui/assets/customers/svg/guggenheim.svg';
import hertzLogo from '@/ui/assets/customers/svg/hertz.svg';
import holidayInnLogo from '@/ui/assets/customers/svg/holidayInn.svg';
import houlihanLokeyLogo from '@/ui/assets/customers/svg/houlihanLokey.svg';
import jefferiesLogo from '@/ui/assets/customers/svg/jefferies.svg';
import mizuhoLogo from '@/ui/assets/customers/svg/mizuho.svg';
import KKRLogo from '@/ui/assets/customers/svg/KKR.svg';
import lazardLogo from '@/ui/assets/customers/svg/lazard.svg';
import moelisLogo from '@/ui/assets/customers/svg/moelis.svg';
import nomuraLogo from '@/ui/assets/customers/svg/nomura.svg';
import piperSandlerLogo from '@/ui/assets/customers/svg/piperSandler.svg';
import soFiLogo from '@/ui/assets/customers/svg/soFi.svg';
import solomonPartnersLogo from '@/ui/assets/customers/svg/solomonPartners.svg';
import teslaLogo from '@/ui/assets/customers/svg/tesla.svg';
import thomabravoLogo from '@/ui/assets/customers/svg/thomabravo.svg';
import verizonLogo from '@/ui/assets/customers/svg/verizon.svg';
import wellsFargoLogo from '@/ui/assets/customers/svg/wellsFargo.svg';
import williamBlairLogo from '@/ui/assets/customers/svg/williamBlair.svg';

export default [
  {
    src: bankOfAmericaLogo,
    title: 'Bank Of America',
  },
  {
    src: barclaysLogo,
    title: 'Barclays',
  },
  {
    src: blackRockLogo,
    title: 'Black Rock',
  },
  {
    src: blackstoneLogo,
    title: 'Blackstone',
  },
  {
    src: carlyleLogo,
    title: 'Carlyle',
  },
  {
    src: CIBCLogo,
    title: 'CIBC',
  },
  {
    src: citigroupLogo,
    title: 'Citigroup',
  },
  {
    src: dellLogo,
    title: 'Dell',
  },
  {
    src: fordLogo,
    title: 'Ford',
  },
  {
    src: goldmanSachsLogo,
    title: 'Goldman Sachs',
    width: 71,
    height: 30,
  },
  {
    src: guggenheimLogo,
    title: 'Guggenheim',
  },
  {
    src: hertzLogo,
    title: 'Hertz',
  },
  {
    src: holidayInnLogo,
    title: 'Holiday Inn',
  },
  {
    src: houlihanLokeyLogo,
    title: 'Houlihan Lokey',
  },
  {
    src: jefferiesLogo,
    title: 'Jefferies',
  },
  {
    src: KKRLogo,
    title: 'KKR',
  },
  {
    src: lazardLogo,
    title: 'Lazard',
  },
  {
    src: mizuhoLogo,
    title: 'Mizuho',
  },
  {
    src: moelisLogo,
    title: 'Moelis',
  },
  {
    src: nomuraLogo,
    title: 'Nomura',
  },
  {
    src: piperSandlerLogo,
    title: 'Piper Sandler',
  },
  {
    src: soFiLogo,
    title: 'SoFi',
  },
  {
    src: solomonPartnersLogo,
    title: 'Solomon Partners',
  },
  {
    src: teslaLogo,
    title: 'Tesla',
  },
  {
    src: thomabravoLogo,
    title: 'Thomabravo',
  },
  {
    src: verizonLogo,
    title: 'Verizon',
  },
  {
    src: wellsFargoLogo,
    title: 'Wells Fargo',
  },
  {
    src: williamBlairLogo,
    title: 'Willoam Blair',
  },
];
